import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/secure-the-high-ground/",
    label: "Do You Have the High Ground",
  },
]

const GaForm = ({ data }) => {
  return (
    <Layout>
      <Seo title="Success! Check your email for download" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="grid--bg--gated-assets ">
        <section className="section-title-top--with-form">
          <div className="container">
            <div className="row base-height">
              <div className="col-sm-8 offset-sm-2 text-center ">
                <h1>Thank You!</h1>
                <p>
                  Your inquiry has been submitted to The Vigilant Team. Please
                  check your email for the Ebook download, it should arrive
                  within the hour!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}
export default GaForm
